import QuoteRightIcon from '../../../assets/quote-right.inline.svg';
import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${props => props.theme.colors.alto};
  line-height: 2.9rem;
  margin-bottom: 1.4rem;

  @media ${props => props.theme.breakpoints.md} {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 0.8rem;
  }
`;

const Quoting = styled.div`
  font-weight: normal;
  font-size: 1.8rem;
  color: ${props => props.theme.colors.alto};
  margin-bottom: 0.4rem;

  @media ${props => props.theme.breakpoints.md} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.schist};

  @media ${props => props.theme.breakpoints.md} {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
`;

const QuoteIconContainer = styled.div`
  padding: 1rem 1rem 1rem 0;
`;

const Quote = ({ quoting, description, content, className }) => {
  return (
    <div className={className}>
      <QuoteIconContainer>
        <QuoteRightIcon />
      </QuoteIconContainer>
      <div>
        <Content>{content}”</Content>
        <Quoting>{quoting}</Quoting>
        <Description>{description}</Description>
      </div>
    </div>
  );
};

const StyledQuote = styled(Quote)`
  font-family: Barlow;
  display: flex;
`;

export default StyledQuote;
